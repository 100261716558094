






































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import {
    getWorkflowMessagesStatistics,
} from '@/api/consoleApi/reports'
import { formatNumber } from '@/utils/formatter'
import { UserModule } from '@/store/modules/user'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'
import VsWorkflowStatisticsMessageFullQuality from '@/modules/workflows/components/VsWorkflowStatisticsMessageFullQuality/Index.vue'
import VsWorkflowStatisticsMessageFullEngagement from '@/modules/workflows/components/VsWorkflowStatisticsMessageFullEngagement/Index.vue'

@Component({
    name: 'VsWorkflowStatisticsMessageOverview',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsLoader,
        VsSectionHeader,
        VsWorkflowStatisticsMessageFullQuality,
        VsWorkflowStatisticsMessageFullEngagement,
    },
})
export default class extends Vue {
    private message: any = null
    private statistics: any = null
    private open = false
    private loading = false

    $refs: any

    get workflowId () {
        return this.$route.params.workflowId || ''
    }

    get user () {
        return UserModule.user
    }

    get canRepotExport () {
        return this.user.configuration.rules.reportExport
    }

    get sentThresholds () {
        return get(AppModule.consoleConf, 'sentThresholds', { unsubscription: 3, bounce: 7 })
    }

    get isUnsubscriptionUnderThreshold () {
        if (!this.statistics) return false
        return this.statistics.unsubscriptions.unsubscription_percentage < this.sentThresholds.unsubscription
    }

    get isBounceUnderThreshold () {
        if (!this.statistics) return false
        return this.statistics.bounce.bounce_percentage < this.sentThresholds.bounce
    }

    public async openModal (message: any) {
        this.open = true
        this.loading = true
        this.message = JSON.parse(JSON.stringify(message))
        await this.getStatistics()
        this.loading = false
    }

    public closeModal () {
        this.open = false
    }

    private async getStatistics () {
        try {
            const resp = await getWorkflowMessagesStatistics(this.workflowId, this.message.id)
            this.statistics = resp.data.data
        } catch (e) {
            console.log(e)
        }
    }

    private formatNumber (value: number) {
        return formatNumber(value)
    }
}
