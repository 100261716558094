











































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import { deleteWorkflow, getWorkflow, getWorkflowMessages, updateWorkflow } from '@/api/consoleApi/workflows'
import VsTabsHeading from '@/components/VsTabsHeading/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import EditNameModal from '@/modules/workflows/components/EditNameModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'
import { get } from 'lodash'
import { getWorkflowStatistics, getWorkflowMessagesStatistics } from '@/api/consoleApi/reports'
import { formatNumber } from '@/utils/formatter'
import VsWorkflowStatisticsCard from '@/modules/workflows/components/VsWorkflowStatisticsCard/Index.vue'
import VsWorkflowStatisticsMessageOverview from '@/modules/workflows/components/VsWorkflowStatisticsMessageOverview/Index.vue'

@Component({
    name: 'WorkflowStatistics',
    components: {
        VsSectionHeader,
        VsContainer,
        VsTabsHeading,
        VsDropdownButton,
        EditNameModal,
        VsConfirm,
        VsLoader,
        VsWorkflowStatisticsCard,
        VsWorkflowStatisticsMessageOverview,
    },
})
export default class extends Vue {
    loading = false
    loadingStatistics = false
    workflow: Workflow | null = null
    $refs!: any
    list = null
    private tabOpen = ''
    private messages: any[] = []
    private missingSteps: string[] = []
    private statistics: {
        total_sent_email: number
        total_sent_sms: number
        total_workflow_completed: number
    }| null = null

    pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    get activeTab () {
        return this.$route.name
    }

    get tabs () {
        const tabs = [
            {
                label: 'Riepilogo',
                id: 'editWorkflow',
                to: {
                    name: 'editWorkflow',
                },
            },
            {
                label: 'Statistiche',
                id: 'workflowStatistics',
                to: {
                    name: 'workflowStatistics',
                },
            },
            {
                label: 'Coda di invio',
                id: 'workflowQueue',
                to: {
                    name: 'workflowQueue',
                },
            },
        ]

        return tabs
    }

    get workflowId () {
        return this.$route?.params?.workflowId || ''
    }

    get badgeStatusAspect () {
        switch (this.workflow?.status || null) {
            case 'ready':
                return 'success'
            case 'pause':
                return 'warning'
            case 'draft':
            default:
                return 'grey'
        }
    }

    get badgeStatusIcon () {
        if (!this.workflow?.status) return ''
        switch (this.workflow?.status || null) {
            case 'pause':
                return 'pause'
            case 'ready':
                return 'double-check'
            case 'draft':
                return 'edit'
        }
    }

    get params () {
        const params = {
            orderBy: 'priority',
            sortedBy: 'asc',
            page: this.pagination.page,
            include: 'statistics',
            limit: this.pagination.itemsPerPage,
        }

        return params
    }

    openTab (tabName: string) {
        this.tabOpen = tabName
    }

    beforeMount () {
        this.getWorkflow()
        this.getWorkflowStatistics()
        this.getMessageStatistics()
    }

    async getWorkflow () {
        this.missingSteps = []
        try {
            const resp = await getWorkflow(this.workflowId, { include: 'recipient.store,messages,messages.sms,messages.email.footer' })
            this.workflow = resp.data.data
        } catch (e) {
            const code = get(e, 'response.status', null)
            if (code === 404) {
                this.$root.$vsToast({
                    timeout: 3000,
                    heading: 'Marketing automation non trovata',
                    aspect: VsToastAspectEnum.alert,
                })
                this.$router.replace({ name: 'workflows' })
                return
            }
            console.log(e)
        }
    }

    private async getMessageStatistics () {
        console.log('getMessageStatistics entro', this.messages)
        this.loadingStatistics = true
        // const statistics: any[] = []
        const resp = await getWorkflowMessages(this.workflowId, this.params)
        this.messages = resp.data.data
        // console.log('getMessageStatistics resppppp', messages)
        // for (const message of messages) {
        //     console.log('nel for')
        //     try {
        //         console.log('nel try')
        //         const resp = await getWorkflowMessagesStatistics(this.workflowId, message.id)
        //         console.log('nel try dopo chiamata')
        //         statistics.push({
        //             id: message.id,
        //             name: message.name,
        //             type: message.entity_type,
        //             statistics: resp.data.data,
        //         })
        //         console.log('nel try dopo push')
        //     } catch (error) {
        //         console.error(`Errore nel recupero delle statistiche per il messaggio ${message.id}:`, error)
        //     }
        // }

        // console.log('getMessageStatistics fuori')
        // this.messageStatistics = statistics
        this.loadingStatistics = false
    }

    @Watch('workflowId', { immediate: false })
    private async getWorkflowStatistics () {
        this.loading = true
        try {
            const resp = await getWorkflowStatistics(this.workflowId)
            this.statistics = resp.data.data
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    async editAutomationName (event: any) {
        this.loading = true
        try {
            await updateWorkflow(this.workflow?.id || '', {
                name: event.name,
            })
            this.getWorkflow()
            this.$root.$vsToast({
                heading: 'Nome modificato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del nome',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async deleteWorkflow () {
        if (!this.workflow) return ''
        try {
            await this.$refs.confirmDelete.openConfirm()
        } catch (e) {
            return
        }

        this.loading = true
        try {
            await deleteWorkflow(this.workflowId)
            this.$root.$vsToast({
                heading: 'Flusso eliminato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$router.replace({
                name: 'workflows',
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante l\'eliminazione del flusso',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private formatNumber (value: number) {
        return formatNumber(value)
    }

    private openMessageStatistics (message: any) {
        this.$refs.vsWorkflowStatisticsMessageOverview.openModal(message)
    }
}
